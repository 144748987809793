/* import fonts(Kavivanar and Inter)*/
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Kavivanar&display=swap");

/* Variables */
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-1: rgba(5, 102, 141, 1);
  --blue-2: rgba(57, 197, 185, 1);
  --purple: rgba(164, 96, 169, 1);
  --buster: rgba(215, 241, 113, 1);
  --red: rgba(255, 0, 0, 1);
  --white: rgba(255, 255, 255, 1);
  --gray: #f5f5f5;
  --font-size-s: 12px;
  /* 24 */
  --font-size-m: 18px;
  /* 28 */
  --font-size-l: 22px;
  --font-size-xl: 25px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 32px;
  --font-size-xxxxl: 35px;
  --font-family-inter: "Inter";
  --font-family-kavivanar: "Kavivanar";
  --animate-duration: 0.1s;
}

/* styles */
body {
  position: relative;
  font-family: var(--font-family-inter);
  font-weight: 400;
  font-style: normal;
  /* overflow: hidden; */
}
#login,
#Terms,
#faq,
#aboutus {
  background-color: var(--purple);
}
#landing {
  background-color: var(--blue-2);
}
.banner-left {
  margin-top: 200px;
}
.banner-left-signup {
  margin-top: 38px;
}
#notfound {
  background-color: var(--buster);
}
#blog {
  background-color: var(--gray);
}
a {
  text-decoration: none;
}
.header-container {
  /* margin: 0 275px; */
  position: absolute;
  z-index: 5;
  width: 100%;
  margin: 0 auto;
}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item {
  background: var(--white);
  border-radius: 35px;
  color: var(--blue-1);
  line-height: 29px;
  font-size: var(--font-size-m);
  text-align: center;
  padding: 11px 52px;
  margin-right: 28px;
  z-index: 20;
  position: relative;
}
.top-banner-login {
  padding-bottom: 200px !important;
}
.top-banner-login.register {
  padding-bottom: 313px !important;
}
.top-banner-aboutus {
  padding-top: 263px !important;
  padding-bottom: 100px !important;
}
.aboutus-top-box {
  background-color: var(--white);
  border-radius: 109px;
  padding: 80px 62px;
  color: var(--purple);
  font-size: var(--font-size-m);
  margin-bottom: 80px;
  text-align: center;
}
.aboutus-middle-back-box {
  position: absolute;
  z-index: -1;
  background-color: var(--blue-2);
  border-radius: 100px 100px 20px 20px;
  padding-top: 34px;
  height: 600px;
  color: var(--white);
  font-size: var(--font-size-xxl);
  width: 69%;
  text-align: center;
  margin: 0 12%;
}
.aboutus-middle-box-slides {
  margin-top: 140px;
}

.img-box img {
  width: 75%;
  margin: 0 auto;
}

.aboutus-middle-box-slides .aboutus-slide {
  padding: 25px;
  border-radius: 210px;
  background-color: var(--white);
  max-height: 900px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aboutus-middle-box-slides .aboutus-slide .slide-title {
  margin-top: 20px;
  color: var(--purple);
  font-size: var(--font-size-l);
  text-align: center;
  font-weight: 900;
  line-height: 1.2;
  /* Adjusts spacing between lines for multi-line text */
  min-height: 50px;
  /* Ensures consistent height for alignment */
}

.aboutus-middle-box-slides .aboutus-slide .slide-job {
  margin-top: 10px;
  color: var(--blue-1);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  min-height: 40px;
  /* Ensures consistent height for job title */
}

.aboutus-middle-box-slides .aboutus-slide .slide-description {
  margin-top: 20px;
  font-size: var(--font-size-m);
  text-align: center;
  margin-bottom: 40px;
  height: 338px;
  overflow-y: scroll;
}

.slide-social a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}
/* Set the width and height of the scrollbar */
.slide-description::-webkit-scrollbar {
  width: 5px;
  height: 12px;
}

/* Set the color of the scrollbar track */
.slide-description::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Set the color of the scrollbar thumb */
.slide-description::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Set the color of the scrollbar thumb on hover */
.slide-description::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.aboutus-middle-box-slides .aboutus-slide .slide-social {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-middle-box-slides .aboutus-slide .img-box {
  border-radius: 50%;
}
.aboutus-bottom-box {
  margin-top: 20px;
  width: 100%;
}
.aboutus-bottom-box .aboutus-bottom-head {
  background-color: var(--white);
  padding: 19px 49px;
  color: var(--purple);
  font-size: var(--font-size-l);
  border-radius: 60px 60px 0px 0px;
  width: 219px;
}
.aboutus-bottom-box .aboutus-bottom-body {
  background-color: var(--white);
  padding: 51px 51px 51px 54px;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 109px 109px 109px;
  width: 100%;
}
.aboutus-bottom-body-right {
  padding: 50px;
  background-color: var(--purple);
  border-radius: 72px;
}
.aboutus-bottom-body-right svg {
  margin: 0 20px;
}
.aboutus-bottom-body-right div:first-child {
  margin-top: 50px;
}
.aboutus-bottom-body-left-text {
  color: purple;
  font-size: var(--font-size-m);
}
.aboutus-bottom-body-left-container {
  margin-bottom: 40px;
}
#aboutus .container {
  z-index: 1;
  position: relative;
}
.dropdown_menu {
  position: relative;
  background-color: var(--blue-1);
  color: white;
  cursor: pointer;
}
.nav-item.activeprofile,
.nav-item.activeprofile:hover {
  padding: 5px !important;
}
.nav-item.activeprofile .login-nav-item {
  background-color: var(--white) !important;
  padding: 6px 25px;
  border-radius: 35px;
  color: var(--blue-1);
}

.dropdown_menu ul {
  left: -5px;
  padding-bottom: 20px;
  right: -5px;
  top: -5px;
  padding: 54px 0 10px !important;
  margin: 0 !important;
  background-color: var(--blue-1) !important;
  border-radius: 26px;
  z-index: -1;
  background-color: var(--blue-1);
}
.dropdown_menu ul li {
  margin: 0;
  z-index: 22;
  list-style-type: none;
  color: white;
  padding: 3px 0px;
  cursor: pointer;
  line-height: 29px;
  font-size: var(--font-size-m);
}

.pngwing-login {
  /* left: 70.49%; */
  right: 0.35%;
  top: 4.49%;
  bottom: 7.62%;
  position: absolute;
  z-index: 5;
  width: 61%;
}
.nav-item.active {
  background-color: var(--buster);
}
.login-nav-item,
.login-nav-item:hover {
  background-color: var(--blue-1) !important;
  color: var(--white);
}

.top-banner {
  height: 1088px;
  overflow: hidden;
}
/* .cir-1 {
  border-radius: 50%;
  margin-top: -154px;
  width: 1207px;
  height: 1226px;
  z-index: -1;
  background: var(--buster);
  margin-left: 149px;
} */
.pngwing-1 {
  position: absolute;
  right: 0px;
  width: 55.4%;
  height: 84%;
  top: 8%;
}
.reg-5 {
  /* position: absolute; */
  width: 34.3%;
  height: 233px;
  top: 148px;
  background: var(--purple);
  display: flex;
  justify-content: center;
  border-radius: 1e15px;
  border-top-left-radius: 0;
  text-align: right;
  border-bottom-left-radius: 0;
  padding: 0 3%;
  z-index: 2;
}
.reg-6 {
  width: 87%;
  /* height: 558px; */
  margin-top: -130px;
  top: 148px;
  background: var(--purple);
  border-radius: 1e24px;
  border-top-left-radius: 0;
  text-align: center;
  border-bottom-left-radius: 0;
  padding: 3%;
  z-index: 2;
  white-space: break-spaces;
  line-break: normal;
}

.reg-container-6 {
  /* padding-left: 48px; */
  padding-top: 9%;
  display: inline-block;
  margin-right: 5%;
}
.reg-title-6 {
  font-family: var(--font-family-kavivanar);
  font-size: var(--font-size-xxxxl);
  line-height: 62px;
  text-align: left;
  color: var(--white);
  width: 327px;
}
.reg-desc-6 {
  font-size: var(--font-size-m);
  line-height: 34px;
  text-align: left;
  color: var(--white);
  width: 505px;
}

.cir-4,
.cir-1,
.cir-6,
.cir-8 {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-2);
}

.cir-6 {
  width: 24%;
  padding: 3%;
}
.cir4-container {
  margin-top: -95px;
  margin-bottom: 40px;
}
.cir4-container.smile {
  margin-top: -12px;
}
.cir8-container {
  margin-top: -75px;
  /* margin-bottom: 40px; */
}
.cir1-container {
  margin-top: -267px;
  margin-bottom: 40px;
}
.features {
  position: relative;
  /* height: 616px; */
  padding: 50px 0;
  border-radius: 522px/57px;
  background-color: var(--white);
  width: 100%;
  margin-top: -115px;
}

.help-button a {
  color: var(--white);
}
.feature-item {
  text-align: center;
  color: purple;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
}
.feature-item h3 {
  font-size: var(--font-size-m);
  line-height: 29px;
}
.feature-item svg {
  margin-bottom: 39px;
  /* change */
  width: 23%;
  height: 70px;
}

.features2-items {
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  flex-direction: row;
}
.features2-items-purple {
  border-radius: 81px;
  position: relative;
  padding: 10px 44px;
  margin-bottom: 54px;
}
.features2-items-purple svg {
  height: 84px;
  width: 65px;
}
.features2-items-white {
  position: absolute;
  width: 78.53%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--white);
  border-radius: 1e9px;
  display: flex;
  line-height: 29px;
  justify-content: center;
  color: var(--purple);
  align-items: center;
  font-size: var(--font-size-m);
  padding-left: 20px;
}

.bg-purple {
  background-color: var(--purple);
}
/* .features2 {
  padding-top: 300px;
} */
.graph1-container {
  width: 100%;
  height: 550px;
  background: var(--white);
  border-radius: 275px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 55px;
  position: relative;
}
.graph1-desc {
  color: var(--purple);
  line-height: 38.73px;
  font-size: var(--font-size-xxl);
  text-align: center;
}

.reviews-container {
  position: relative;
  border-radius: 367px/191px;
  background-color: var(--white);
  width: 100%;
  margin-top: -80px;
  padding-bottom: 100px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  position: relative;
}
.reviews-title {
  padding-top: 115px;
  line-height: 48.41px;
  font-size: var(--font-size-xxxl);
  color: var(--purple);
}
.reviews-items {
  margin-top: 57px;
}
.reviews-item {
  background-color: var(--purple);
  /* height: 1088px; */
  border-radius: 302px;
  display: flex;
  /* width: 497px; */
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
}
.cir-7 {
  width: 54%;
  height: 30%;
  border-radius: 50%;
  margin-top: 38px;
  /* background-color: var(--white); */
  overflow: hidden;
}
.reviews-item-title {
  margin: 50px 0;
  color: var(--white);
  font-family: var(--font-family-kavivanar);
  line-height: 59px;
  font-size: var(--font-size-xxxl);
}
.header-svg {
  width: 100%;
  position: relative;
}
#blog .header-svg {
  padding-bottom: 300px;
}
.reviews-item-desc {
  line-height: 29px;
  font-size: var(--font-size-m);
  font-family: var(--font-family-inter);
  color: var(--white);
  padding: 0 20px;
  text-align: center;
}
.owl-left {
  position: absolute;
  top: 50% !important;
  padding: 0 25px;
  border-radius: 50% !important;
  width: 75px;
  height: 75px;
  padding: 5px 8px !important;
  left: 30px !important;
  background-color: var(--blue-1) !important;
}
.owl-left svg {
  width: 50%;
}
.owl-right {
  position: absolute;
  top: 50% !important;
  padding: 0 25px;
  border-radius: 50% !important;
  width: 75px;
  height: 75px;
  padding: 5px 8px !important;
  right: 30px !important;
  background-color: var(--blue-1) !important;
}
.owl-right svg {
  width: 50%;
}
.owl-carousel:hover .owl-controls .owl-buttons .owl-prev {
  opacity: 1 !important;
}
.owl-carousel:hover .owl-controls .owl-buttons .owl-next {
  opacity: 1 !important;
}
.cir-8 {
  padding: 1% 3%;
  height: 100%;
  width: 16%;
}
.video-frame {
  /* border: 10px solid var(--purple); */
  border-radius: 20px;
  max-height: 580px;
  width: 65%;
  /* background-color: gray; */
  margin: 0 auto;
  margin-top: 164px;
  overflow: hidden;
  margin-bottom: 54px;
  position: relative;
}
.video-desc {
  color: var(--purple);
  font-size: var(--font-size-m);
  line-height: 29px;
  width: 70%;
  margin: 0 auto 200px;
  text-align: center;
}
.help-container img {
  width: 90%;
}
.video-frame #video {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 10px solid var(--purple);
}
.video-frame .controls {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  opacity: 0.2;
  transition: opacity 0.4s;
}
.video-frame:hover .controls {
  opacity: 1;
}
.video-frame .controls button {
  background: transparent;
  color: #fff;
  font-weight: bolder;
  text-shadow: 2px 1px 2px #000;
  border: none;
  cursor: pointer;
}
.fa {
  font-size: 20px !important;
}
.video-container {
  position: relative;
  /* height: 1035px;  */
  /* border: 2px solid #000; */
  border-radius: 522px/57px;
  background-color: var(--white);
  width: 100%;
  margin-top: -90px;
}
.cir-9 {
  position: absolute;
  left: 37%;
  top: 90%;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  z-index: 10;
  background-color: var(--blue-2);
  padding: 75px 82px;
}
.footer-con {
  display: flex;
  flex-direction: row;
}
.footer-items {
  display: flex;
  flex-direction: row;
}
.help-container {
  margin-top: -99px;
  border-radius: 367px/191px;
  background-color: var(--white);
  width: 100%;
  padding-top: 86px;
  padding-bottom: 230px;
}
.help-container img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.help-col {
  padding-top: 25%;
  /* justify-content: center;
  display: flex; */
}
.help-cir-title {
  width: 58.4%;
  margin-left: 25.8%;
  height: 368px;
  display: flex;
  background: var(--blue-2);
  border-radius: 228px 228px 0px 0px;
  line-height: 43px;
  justify-content: center;
  color: var(--buster);
  padding-top: 100px;
  font-size: 36px;
}
.help-cir-desc {
  /* height: 368px; */
  width: 84.3%;
  margin-top: -153px;
  background: var(--buster);
  border-radius: 228px 0px 0px 228px;
  font-size: var(--font-size-m);
  color: var(--purple);
  padding: 63px 35px 50px 98px;
  line-height: 29px;
}
.help-button {
  height: 110px;
  text-align: center;
  background: var(--blue-1);
  border-radius: 166px;
  justify-content: center;
  display: flex;
  line-height: 58px;
  color: var(--white);
  align-items: center;
  font-size: var(--font-size-xxxxl);
  width: 582px;
}
.footer-background {
  background-color: var(--blue-2);
  height: 200px;
  width: 100%;
  max-width: 1676px;
  margin: 0 auto;
  z-index: -1;
}
.footer-background.aboutus-footer {
  background-color: var(--purple);
}
.footer-background.blog-footer {
  background-color: var(--white);
}
.footer-container {
  margin-top: -155px;
  border-radius: 262px/147px;
  background-color: var(--buster);
  border-bottom-left-radius: 0;
  width: 100%;
  border-bottom-right-radius: 0;
  padding-top: 59px;
  z-index: 10;
  max-width: 1676px;
  margin-left: auto;
  margin-right: auto;
}
.footer2-container {
  position: absolute;
  left: 0;
  bottom: -244px;
  height: 400px;
  border-radius: 350px;
  background-color: var(--buster);
  width: 100%;
  padding-top: 59px;
  z-index: 10;
  max-width: 1676px;
  margin-left: auto;
  margin-right: auto;
}
.footer-button {
  background-color: var(--white);
  padding: 18px 38px;
  line-height: 19px;
  font-size: 17px;
  color: var(--blue-1);
  border-radius: 1e7px;
  margin: 0px 23px;
  text-align: center;
}
.footer-copywrite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 31px;
  color: var(--purple);
  margin-bottom: 6px;
}
.footer-copywrite2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 35px 0;
  margin-left: -87px;
  color: var(--purple);
}
.footer-copywrite svg {
  margin-right: 30px;
}
.help-button-con {
  left: 13%;
  /* bottom: -14%; */
}
.login-cir-1 {
  position: absolute;
  left: 0;
  top: 0;
}
.login-cir-2 {
  position: absolute;
  left: 0;
  top: 975px;
}
.register-cir-2 {
  position: absolute;
  left: 0;
  top: 985px;
}
.login-cir-3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.login-cir-3 svg {
  width: 34%;
}
.login-form-container {
  /* position: absolute; */
  width: 554px;
  height: 800px;
  background: var(--white);
  border-radius: 1e7px;
  padding: 152px 68px;
  z-index: 1;
}
.login-form-signup {
  /* position: absolute; */
  width: 554px;
  /* height: 876px; */
  background: var(--white);
  border-radius: 1e7px;
  padding: 89px 48px;
  margin-top: 86px;
  z-index: 1;
}
.checkmail-container {
  /* position: absolute; */
  width: 800px;
  height: 450px;
  background: var(--white);
  border-radius: 1e7px;
  /* padding: 123px 110px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 174px;
  align-items: center;
  text-align: center;
}
.checkmail-cir-2 {
  position: absolute;
  left: 0;
  top: 975px;
}
.checkmail-desc {
  color: var(--purple);
  line-height: 29px;
  font-size: var(--font-size-m);
  width: 412px;
}
.form-group {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.top-banner-reset {
  padding-bottom: 100px !important;
}
.form-input,
.form-input:focus {
  height: 54px;
  background: var(--white);
  border: 2.5px solid var(--purple);
  border-radius: 1e8px;
  padding: 27px 79px 27px 45px;
  color: var(--purple);
  outline-offset: 0px;
  outline: none;
  font-size: 14px;
  width: 100%;
}
.form-input::placeholder {
  color: var(--purple);
}
.form-label {
  margin-left: 45px;
  color: var(--purple);
  font-size: var(--font-size-m);
}
.login-form-button {
  opacity: 1 !important;
  width: 100%;
  background-color: var(--blue-1);
  color: var(--white) !important;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  margin-top: 50px;
  height: 73px;
  border-radius: 1e27px;
  margin-bottom: 27px;
  font-size: var(--font-size-m);
}
.login-form-button.disabled {
  background-color: #6c757d !important;
}
.login-form-button.submited {
  background: #288d05 !important;
}
.checkmail-button {
  width: 172px;
  background-color: var(--blue-1);
  color: var(--white);
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
  margin-top: 50px;
  height: 90px;
  border-radius: 1e27px;
  margin-bottom: 27px;
  font-size: var(--font-size-m);
  padding: 24px 59px;
}
.login-form-d {
  color: var(--blue-1);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-policy {
  color: var(--blue-1);
  display: contents;
  margin: 0 10px;
}
.contents {
  width: 100%;
  max-width: 1676px;
  margin-right: auto;
  margin-left: auto;
}
.resetpass-form-container {
  /* position: absolute; */
  width: 554px;
  height: 800px;
  background: var(--white);
  border-radius: 1e7px;
  display: flex;
  /* padding: 152px 68px; */
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 1;
}
.login-form-button:hover,
.checkmail-button:hover {
  color: var(--white);
}
.signup-d {
  display: flex;
  justify-content: center;
  color: var(--purple);
  align-items: center;
  text-align: center;
  line-height: 29px;
  font-size: 16px;
}
.notfound-cir-1 {
  position: absolute;
  left: 0;
  top: 0;
}
.notfound-cir-2 {
  position: absolute;
  right: 0;
  top: 0;
}

.notfound-cir-3 {
  position: absolute;
  right: 0;
  top: 824px;
}
.notfound-reg-1 {
  display: flex;
  width: 471px;
  height: 266px;
  background: var(--purple);
  border-radius: 133px;
  text-align: center;
  align-items: center;
  font-family: var(--font-family-kavivanar);
  font-size: 90px;
  line-height: 133px;
  justify-content: center;
  color: var(--white);
}
.notfound-reg-2 {
  display: flex;
  width: 1043px;
  height: 342px;
  background: var(--purple);
  border-radius: 389.5px;
  text-align: center;
  align-items: center;
  font-family: var(--font-family-kavivanar);
  font-size: 80px;
  line-height: 133px;
  justify-content: center;
  color: var(--white);
  margin-top: -100px;
  z-index: 1;
}
a:not(.nav-item):hover {
  color: var(--white);
}
.login-form-d:hover,
.signup-policy:hover {
  color: var(--blue-2) !important;
}
.notfound-reg-button {
  display: flex;
  width: 398px;
  height: 206px;
  border-radius: 999999px;
  text-align: center;
  align-items: flex-end;
  font-size: 36px;
  line-height: 53px;
  justify-content: center;
  color: var(--white);
  margin-top: -100px;
  background-color: var(--blue-1);
  z-index: 0;
  padding-bottom: 30px;
  font-family: var(--font-family-kavivanar);
}
.terms-cir-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.terms-cir-2 {
  position: absolute;
  right: 114px;
  top: 0;
  z-index: 0;
}

.terms-cir-3 {
  position: absolute;
  left: 0;
  top: 692px;
  z-index: 0;
}
.terms-cir-4 {
  position: absolute;
  right: 0;
  top: 819px;
  z-index: 0;
}
.footer svg {
  max-width: 1676px;
  width: 100%;
  margin: 0 auto;
}
.terms-container {
  width: 100%;
  /* height: 882px; */
  background: var(--white);
  border-radius: 441px;
  z-index: 2;
  align-items: center;
  display: flex;
  padding-top: 70px;
  flex-direction: column;
}
.terms-title {
  color: var(--blue-1);
  line-height: 43px;
  font-size: 36px;
}
.terms-desc {
  color: var(--purple);
  line-height: 29px;
  font-size: var(--font-size-m);
  padding: 100px 175px;
}
.terms-button {
  margin-bottom: 42px;
  display: flex;
  width: 172px;
  height: 77px;
  background: var(--blue-1);
  border-radius: 1e8px;
  font-size: var(--font-size-m);
  color: var(--white);
  justify-content: center;
  text-align: center;
  align-items: center;
}
.faq-cir-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.faq-cir-2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.faq-cir-3 {
  position: absolute;
  right: 0;
  top: 255px;
  z-index: 0;
}
.faq-cir-4 {
  position: absolute;
  left: 0;
  top: 706px;
  z-index: 0;
}
.faq-cir-5 {
  position: absolute;
  right: 0;
  bottom: 120px;
  z-index: 0;
}
.blog-cir-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.blog-cir-2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 93.8%;
}
.blog-cir-3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  /* transform: scaleX(-1); */
}
.footer-container-2 {
  margin-top: -155px;
  border-radius: 350px;
  background-color: var(--buster);
  /* border-bottom-left-radius: 0; */
  width: 100%;
  /* border-bottom-right-radius: 0; */
  padding-top: 59px;
  z-index: 10;
  max-width: 1676px;
  margin-left: auto;
  margin-right: auto;
}
.blog-cir-babycheck {
  position: absolute;
  right: 31px;
  top: 8px;
  background: var(--white);
  border: 9px solid #fdda1d;
  width: 171px;
  height: 171px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--blue-1);
  font-size: var(--font-size-xl);
  padding: 14px;
  z-index: 6;
}
.blog-cir-babycheck:hover {
  color: var(--buster);
  background-color: var(--blue-1);
}
.top-banner-faq {
  /* height: 1854px; */
  padding-bottom: 350px;
}
.faq-list-container {
  padding-top: 274px;
  width: 961px;
}
.faq-list-items {
  height: 76px;
  background-color: var(--blue-1);
  padding: 52px 80px;
  color: var(--white);
  line-height: 30px;
  font-size: var(--font-size-l);
  display: flex;
  border-radius: 99999px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 49px;
  position: relative;
}
.faq-list-items.open {
  width: 568px;
  height: 66px;
  background: var(--blue-1);
  border-radius: 999px 999px 0px 0px;
  margin-bottom: 0;
}
.faq-list-items:hover {
  background-color: var(--blue-2);
}
.faq-child-container {
  width: 100%;
  /* height: 573px; */
  background: var(--buster);
  border-radius: 0px 286.5px 286.5px 286.5px;
  display: none;
  margin-bottom: 57px;
  color: var(--blue-1);
  font-size: var(--font-size-m);
  z-index: 3;
  padding: 28px 69px 58px 88px;
}
.faq-item {
  z-index: 1;
}
.faq-child-container.open {
  display: flex;
  flex-direction: column;
}
.faq-child-items {
  border-radius: 96.5px;
  background-color: transparent;
  padding: 0 54px;
  width: 100%;
  margin-top: 25px;
  cursor: pointer;
}
.faq-child-items .faq-child-quiez {
  color: var(--blue-1);
  font-size: var(--font-size-m);
  line-height: 29px;
  margin-bottom: 20px;
}
.faq-child-items .faq-child-answer {
  color: var(--purple);
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 20px;
  width: 100%;
  overflow-wrap: break-word;
  display: none;
}
.faq-child-items.open .faq-child-answer {
  display: block;
}
.faq-child-items.open {
  background-color: var(--white);
}
.footer-cir-1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
/* #blog {
  padding-top: 300px;
} */

.blog-toggler-slide,
.article-toggler-slide {
  background: var(--purple);
  box-shadow: 0px -24px 32px rgb(0 0 0 / 18%);
  border-radius: 0px 60px 0px 0px;
  /* height: 71px; */
  font-size: 28px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 42px;
  margin-left: -33px;
  font-weight: 900;

  z-index: 0;
}
.article-toggler-slide {
  background-color: var(--blue-2);
  border-radius: 60px 60px 0px 0px;
  max-width: 80%;
}
.blog-toggler-slide.active {
  background-color: var(--blue-2);
  height: 78px;
  z-index: 1;
  border-radius: 60px 60px 0px 0px;
}
.blog-tab-contents {
  background: var(--blue-2);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 60px 60px 60px;
  /* padding: 30px; */
  z-index: 3;
  position: relative;
}
.blog-search-result-con ul li {
  margin: 5px 0;
}
.star-button svg path {
  fill: white;
  stroke: var(--purple);
  stroke-width: 1px;
}
.contents:not(#landing, #login, #Terms, #faq, #aboutus, #notfound) {
  background-color: white;
}
.blog-info {
  position: absolute;
  bottom: -144px;
  z-index: -1;
}
.blog-tab {
  display: none;
  position: relative;
  padding: 29px 19px;
}
.blog-tab.active {
  display: block;
}
.blog-tab-item {
  width: 309px;
  /* height: 700px; */
  background: var(--white);
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  padding: 16px;
  /* padding-bottom: 50px; */
}
.color-purple {
  color: var(--purple);
}
.blog-tab-item-img {
  border-radius: 60px;
  overflow: hidden;
  margin-bottom: 10px;
}
.blog-tab-item-img img {
  width: 100%;
  /* height: 300px; */
  cursor: pointer;
}
.blog-tab-item-title:hover {
  color: var(--purple) !important;
}
.blog-tab-item.transparent {
  background-color: transparent !important;
}
.blog-tab-item.border-white {
  border: 1px solid var(--white);
}
.blog-tab-item-title {
  color: var(--purple);
  font-size: 18px;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 800;
  cursor: pointer;
}
.blog-tab-item-desc {
  /* color: var(--purple); */
  font-size: 18px;
  text-align: center;
  display: block;
  width: 100%;
  height: 164px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.star-rating {
  direction: ltr;
  display: flex;
  justify-content: center;
}
.star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.star-button-on svg path {
  fill: #fdda1d;
  stroke: none;
}
.star-button-on svg path {
  color: var(--white);
}
.blog-tab-item-footer-desc {
  font-size: 17px;
  color: var(--blue-1);
}
.blog-tab-item-footer-time {
  font-size: 17px;
  color: var(--purple);
}
.blog-tab-time {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.blog-tab-time div {
  color: var(--white);
}
.popular-container {
  margin-bottom: 48px;
}
.blog-recently-reg-1 {
  border-radius: 0px 60px 60px 0px;
  background-color: var(--purple);
  color: var(--white);
  padding: 18px 84px 27px 34px;
  width: 388px;
  font-size: var(--font-size-xxl);
  margin-bottom: 52px;
}
.blog-recently-grid,
.blog-tab-grid {
  padding: 0 45px;
  display: flex;
  column-gap: 44px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
/* .blog-tab-grid {
  height: 660px;
} */
.blog-tab-item.grid-item,
.blog-tab-item.grid-item {
  margin-bottom: 36px;
  position: relative;
}
.blog-tab-item.grid-item .blog-tab-time {
  right: -35px;
}
.blog-pagination-container {
  margin-top: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 85px; */
  padding-bottom: 85px;
}
.blog-pagination-square-blue {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  background-color: var(--blue-1);
  padding: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.blog-pagination-square-white {
  width: 70px;
  height: 70px;
  border-radius: 15px;
  background-color: var(--white);
  border: 5px solid var(--blue-1);
  padding: 16px 11px;
  display: flex;
  color: var(--blue-1);
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
}
.blog-pagination-square-white.back,
.blog-pagination-square-white.next {
  padding: 18px 50px;
}
.blog-pagination-square-white.active {
  border-color: var(--purple);
  transform: rotate(45deg);
  color: var(--purple);
}
.blog-pagination-square-white:hover {
  color: var(--blue-1) !important;
}
.blog-pagination-square-white.active:hover {
  color: var(--purple) !important;
}

.blog-pagination-square-white.active span {
  transform: rotate(-45deg);
}
.blog-tab-more {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 49px;
}
.blog-tab-more button {
  color: var(--white);
  background-color: var(--blue-1);
  font-size: 24px;
  border-radius: 9999px;
  border: none;
  padding: 11px 75px;
}
.blog-showmore {
  display: none;
}
.blog-showmore.active {
  display: flex;
  column-gap: 44px;
  flex-wrap: wrap;
  justify-content: center;
}
.article-reg-1 {
  width: 724px;
  height: 255px;
  background: #d7f171;
  border-radius: 60px 60px 127.5px 60px;
  z-index: -1;
  margin-top: -46px;
  padding-top: 44px;
  margin-bottom: 117px;
  /* position: relative; */
}
.article-reviews > div {
  padding: 0 47px;
  margin: 77px 0 220px;
}
.article-reg-2 {
  position: absolute;
  width: 732px;
  height: 156px;
  border-radius: 9999px;
  background-color: var(--purple);
  right: 351px;
  /* bottom: 0; */
}
.profile-container {
  background: #c6c6c6;
  border-radius: 0 60px 60px 60px;
  width: 100%;
  margin-top: 95px;
  padding: 59px 27px;
  margin-bottom: 42px;
  z-index: 0;
  position: relative;
  /* height: 873px; */
}
.profile-reg-1 {
  border-radius: 0px 60px 60px 0px;
  background-color: var(--purple);
  color: var(--white);
  padding: 30px 84px 30px 34px;
  /* width: 388px; */
  font-size: var(--font-size-xl);
  position: absolute;
  left: 0;
  top: -75px;
}
.profile-general-left,
.profile-general-middle {
  padding-top: 0;
}
.profile-general-right {
  padding-top: 10px;
}
.profile-form-input {
  width: 83%;
}
.profile-switch {
  /* position: absolute; */
  width: 216px;
  height: 67px;
  background: var(--white);
  border: 3px solid var(--purple);
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--purple);
  overflow: hidden;
  position: relative;
}
.profile-switch span {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  cursor: pointer;
}

.profile-switch-active {
  background-color: var(--purple);
  color: var(--white);
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .select-arrow {
  width: 20px;
} */
.profile-left-button {
  height: 70px;
  background: var(--blue-1);
  border-radius: 999px;
  padding: 8px 70px;
  color: var(--white);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}
.profile-right-button {
  height: 55px;
  background: var(--blue-1);
  border-radius: 999px;
  padding: 11px 30px;
  border: none;
  color: var(--white);
  font-size: 18px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 70;
  width: 150px;
  cursor: pointer;
  position: absolute;
  bottom: 35px;
  z-index: 50;
  right: 120px;
}
.profile-right-button.disabled {
  background-color: var(--black);
  cursor: none;
}

.profile-pregnancy-item-cir::placeholder {
  color: var(--purple);
}
.profile-pregnancy-item-cir.disabled {
  background-color: rgba(235, 235, 235, 0.9);
}
/* input:disabled {
  background: none !important;
} */
.birthday-container > div {
  width: 25%;
}
.profile-pregnancy-item-cir.bmi {
  background-color: var(--blue-1);
  border-color: var(--blue-1) !important;
  color: var(--white);
}
.profile-pregnancy-item-cir.bmi::placeholder {
  color: var(--white);
}
.birthday-container div[role="alert"] {
  font-size: 14px;
}
.profile-middle-button {
  height: 75px;
  background: var(--blue-1);
  border-radius: 999px;
  padding: 8px 70px;
  color: var(--white);
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 8px; */
  width: 100%;
  margin-top: 56px;
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.select-container {
  overflow: hidden;
}
.select-arrow {
  transform: rotate(0);
  transition: all 0.2s;
}
.select-arrow.active {
  transform: rotate(180deg);
  transition: all 0.2s;
}
.select-list {
  background-color: var(--white);
  border-radius: 60px;
  padding-right: 25px !important;
  padding: 24px 79px;
  display: none;
  animation-duration: 0.2s;
}
.select-list.active {
  display: block;
}
.select-scroll {
  height: 300px;
  overflow-y: scroll;
}

.select-list span {
  display: block;
  color: var(--purple);
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  margin: 5px 0;
}
.select-scroll::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
  border-radius: 10px;
}
.select-scroll::-webkit-scrollbar-track {
  background: var(--purple); /* color of the tracking area */
}
.select-scroll::-webkit-scrollbar-thumb {
  background-color: var(--buster);
  border-radius: 13px;
  border: none;
  height: 60px;
}
.image-item__btn-wrapper {
  position: absolute;
  right: 48px;
}
.select-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.profile-general-right-img {
  width: 180px;
  height: 180px;
  border: 10px solid var(--purple);
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 82px;
}
.profile-pregnancy-left-con,
.profile-pregnancy-right-con {
  padding: 0 66px;
  margin: 0 auto;
}
.profile-pregnancy-left,
.profile-pregnancy-right {
  padding: 184px 30px 115px;
  background-color: var(--white);
  border-radius: 78px;
  position: relative;
}
.profile-pregnancy-head {
  background-color: var(--purple);
  padding: 26px 22px;
  color: var(--white);
  font-size: var(--font-size-m);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
}
.profile-pregnancy-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 2fr));
  width: 100%;
  grid-gap: 1rem;
  justify-items: center;
}

.profile-pregnancy-grid2 {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 2fr));
  width: 100%;
  grid-gap: 1rem;
  justify-items: center;
}
.resetpass-form-container form {
  width: 75%;
}
/* .profile-pregnancy-grid2 {
    margin-top: 32px;
    display: inline-grid;
    grid-template-columns: 170px 170px;
    grid-column-gap: 123px;
    grid-row-gap: 26px;
    padding: 0 29px;
    justify-items: center;
    align-items: center;
  } */
.profile-pregnancy-item span {
  color: var(--purple);
  margin-left: 35px;
  /* font-size: 18px; */
}
.profile-pregnancy-item-cir {
  width: 141px;
  height: 62px;
  background: var(--white);
  border: 3px solid var(--purple);
  border-radius: 60px;
  margin-bottom: 15px;
  display: flex;
  color: var(--purple);
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.profile-switch-label {
  color: var(--purple);
  font-size: 16px;
  margin-left: 15px;
}
.text-buster svg {
  fill: var(--buster);
  width: 36px;
  height: 31px;
  /* background-color: var(--buster); */
}

.css-13cymwt-control {
  width: 100%;
  border: none;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.article_mainimage {
  object-fit: cover;
  min-height: 600px;
}
.article-content-container {
  margin-top: 260px;
  padding: 0 40px;
  font-size: var(--font-size-m);
  text-align: left;
  margin-bottom: 100px;
  line-break: normal;
}
.article-content-container .article-content-title {
  color: var(--purple);
}
.answer-list .answer-parent > div {
  position: relative;
  z-index: 2001;
}
.answer-rating-up,
.answer-rating-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.review-show-more {
  position: absolute;
  bottom: -10%;
  color: var(--purple);
  left: 34%;
  border-radius: 35px;
  padding: 20px;
  cursor: pointer;
  background-color: var(--buster);
}
.answer-rating-up span,
.answer-rating-down span {
  color: #05668d;
  font-size: 20px;
}
ul {
  list-style-type: none;
}
.answer-list .answer-parent:nth-of-type(1) .answer-content-parent,
.review-answer-child .answer:nth-of-type(2) .answer-content {
  background-color: #d9d9d9;
}
.answer-list .answer-parent:nth-of-type(2) .answer-content-parent,
.review-answer-child .answer:nth-of-type(1) .answer-content {
  background-color: var(--purple);
}
.answer-list .answer-parent .answer-content-parent,
.answer-list .answer-parent .answer-content {
  position: relative;
  align-items: center;
  width: 85%;
  border-radius: 60px;
  padding: 23px 36px 19px 56px;
  margin-right: 20px;
  z-index: 1;
}
.review-answer-child {
  margin-top: -100px;
  padding-left: 0;
}
.answer-parent {
  margin-bottom: 30px;
}
.answer-parent.haschilds {
  margin-bottom: 150px;
}
.review-answer-child .answer-content {
  padding-top: 115px !important;
  z-index: 0 !important;
  width: 80% !important;
}
.answer-list .answer-parent .answer-rating {
  display: flex;
  position: absolute;
  right: 0;
  top: 40%;
}
.review-answer-child .answer .answer-rating {
  top: 60%;
}
.answer-list
  .answer-parent:nth-of-type(1)
  .answer-content-parent
  .review-answer-name,
.review-answer-child
  .answer:nth-of-type(2)
  .answer-content
  .review-answer-name {
  font-size: var(--font-size-m);
  color: var(--purple);
}
.answer {
  position: relative;
}
.answer-list
  .answer-parent:nth-of-type(2)
  .answer-content-parent
  .review-answer-name,
.review-answer-child
  .answer:nth-of-type(1)
  .answer-content
  .review-answer-name {
  font-size: var(--font-size-m);
  color: var(--buster);
}
.answer-list
  .answer-parent:nth-of-type(1)
  .answer-content-parent
  .review-answer-date,
.review-answer-child
  .answer:nth-of-type(2)
  .answer-content
  .review-answer-date {
  font-size: var(--font-size-m);
  color: var(--purple);
}
.answer-list
  .answer-parent:nth-of-type(2)
  .answer-content-parent
  .review-answer-date,
.review-answer-child
  .answer:nth-of-type(1)
  .answer-content
  .review-answer-date {
  font-size: var(--font-size-m);
  color: var(--buster);
}
.answer-list
  .answer-parent:nth-of-type(1)
  .answer-content-parent
  .review-answer-text,
.review-answer-child
  .answer:nth-of-type(2)
  .answer-content
  .review-answer-text {
  font-size: var(--font-size-m);
}
.answer-list
  .answer-parent:nth-of-type(2)
  .answer-content-parent
  .review-answer-text,
.review-answer-child
  .answer:nth-of-type(1)
  .answer-content
  .review-answer-text {
  font-size: var(--font-size-m);
  color: var(--white);
}

/* .answer-list .answer .answer-content:nth-of-type(1) .review-answer-text {
  font-size: var(--font-size-m);
} */
.blog-info-left-container {
  background-color: var(--buster);
  border-radius: 60px 60px 127.5px 60px;
  width: 724px;
  padding: 131px 10px 13px 11px;
  display: flex;
}
.blog-info-right-container {
  background-color: var(--purple);
  border-radius: 99999px;
  width: 724px;
  display: flex;
  position: absolute;
  height: 179px;
  flex-direction: column;
  align-items: flex-end;
  z-index: -2;
  left: 400px;
  top: 84px;
  justify-content: center;
}
.blog-info-right-container .star-rating,
.blog-info-right-container .blog-view {
  margin-right: 60px;
}
.blog-info-right-container .blog-view {
  width: 30%;
  margin-right: 70px;
}
.blog-info-right-container .star-button:not(.star-button-on) svg path {
  fill: none;
  stroke: white;
  stroke-width: 1px;
}
.blog-info-right-container .blog-view span {
  color: white;
}
.blog-info-right-container .star-rating svg {
  width: 33px;
  height: 60px;
}
.blog-info-left-circle {
  border-radius: 50%;
  height: 119px;
  width: 119px;
  background-color: white;
  margin-right: 30px;
  overflow: hidden;
}
.blog-info-left-circle img {
  width: 100%;
}
.blog-info-left-author {
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-xl);
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 15px;
  color: var(--blue-1);
}
.blog-info-left-date {
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 400;
  color: var(--purple);
  font-size: 23px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
}
#article-content figure img {
  max-width: 100%;
  text-align: center;
  margin: auto;
  height: auto;
  display: block;
}

#article-content p {
  line-height: 35px;
  font-size: 21px;
}
.kg-button-card a:hover {
  color: var(--blue-1);
}
.aboutus-cir-1 {
  position: absolute;
  left: 806px;
  top: 0;
  z-index: 0;
}
.aboutus-cir-2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.aboutus-cir-3 {
  position: absolute;
  left: 0;
  top: 739px;
  z-index: 0;
}
.aboutus-cir-4 {
  position: absolute;
  right: 0;
  top: 866px;
  z-index: 0;
}
.babycheck-cir-1 {
  position: absolute;
  left: 741px;
  top: 0;
  z-index: 0;
}
.babycheck-cir-2 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.babycheck-cir-3 {
  position: absolute;
  left: 0;
  top: 686px;
  z-index: 0;
}

.babycheck-container {
  margin: 0 auto;
  width: 100%;
}
.babycheck-box {
  background-color: var(--white);
  border-radius: 1e7px;
  padding: 0 20%;
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.babycheck-notif {
  color: var(--purple);
  font-size: var(--font-size-m);
  margin-bottom: 100px;
}
.babycheck-complete-button {
  border-radius: 1e8px;
  padding: 23px 30px;
  background-color: var(--blue-1);
  color: var(--white);
  font-size: var(--font-size-m);
}
.babycheck-central-circle {
  border: 20px solid var(--white);
  width: 600px;
  height: 600px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}
.babycheck-central-circle-button {
  position: absolute;
  right: 27%;
  top: 25%;
  width: 45%;
  font-weight: bold;
  height: 45%;
  font-size: 30px;
  cursor: pointer;
}
.landing-central-circle-button {
  position: absolute;
  left: -9%;
  top: 18%;
  width: 180px;
  font-weight: 500;
  height: 180px;
  font-size: 25px;
  cursor: pointer;
}
.landing-babycheck {
  display: none;
}
.babycheck-central-circle-childs {
  position: absolute;
  width: 156px;
  height: 156px;
  background-color: var(--blue-1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.babycheck-central-circle-childs.child-1 {
  right: 37%;
  top: -14%;
}
.babycheck-central-circle-childs.child-2 {
  right: 84%;
  top: 21%;
}
.babycheck-central-circle-childs.child-3 {
  right: -14%;
  top: 21%;
}
.babycheck-central-circle-childs.child-4 {
  right: 71%;
  top: 73%;
}
.babycheck-central-circle-childs.child-5 {
  right: 0%;
  top: 73%;
}
.blog-serach-container {
  right: 50px;
  top: 0px;
  justify-content: space-between;
  padding: 10px 12px 10px 27px;
  display: flex;
  width: 400px;
  background-color: var(--blue-1);
  border-radius: 9999px;
  align-items: center;
  z-index: 4;
}
.blog-serach-input {
  background-color: var(--white);
  padding: 10px 10px;
  color: #a460a9;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  text-align: center;
  border: 0;
  margin-left: 15px;
  border-radius: 9999px;
  width: 100%;
}
.blog-serach-input::placeholder {
  color: #a460a9;
}
.footer {
  width: 100%;
  max-width: 1676px;
  margin-right: auto;
  margin-left: auto;
}
.review-answer-text {
  line-break: normal;
  overflow: hidden;
  text-overflow: inherit;
}
.article-reviews-answers-list {
  position: relative;
  z-index: 0;
}
.blog-search-result-con {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -18;
  top: 37%;
  padding: 53px;
  background-color: var(--white);
  border-radius: 15px;
  background-color: var(--blue-1);
  color: var(--bs-white);
  font-size: var(--font-size-m);
  display: none;
}
.blog-search-result-con.show {
  display: block;
}
.blog-search-result-con ul li a {
  color: var(--white);
}
.blog-search-result-con ul li a:hover {
  color: var(--buster);
}
.babycheck-central-circle-childs.show {
  padding: 30px 16px 16px;
  display: block;
  text-align: center;
}
.babycheck-central-circle-childs.show div:first-child {
  font-size: 15px;
  color: var(--white);
}
.babycheck-central-circle-childs.show div:last-child {
  font-size: 40px;
  color: var(--purple);
  font-weight: bold;
}

/* responsive mode css */
.navbar {
  background-color: var(--purple);
}

.logo-title {
  font-family: var(--font-family-kavivanar);
  font-size: var(--font-size-l);
  color: var(--white);
  margin-left: 10px;
}
.nav-item-mob {
  background-color: transparent !important;
  padding: 0 10px !important;
}
.svg-inline--fa.fa-user {
  font-size: 28px;
}
.nav-item-mob .login-nav-item {
  background-color: transparent !important;
}
.header-mobile-container {
  position: fixed;
  z-index: 100;
  background-color: var(--blue-1);
  width: 100%;
  /* height: 100px; */
  padding-bottom: 20px;
}
.header-mobile-container ul {
  padding: 20px;
}

.header-mobile-container ul li {
  /* border: 2px solid #eee; */
  padding: 5px;
  /* border-radius: 5px; */
  margin-bottom: 10px;
}
.header-mobile-container ul li a,
.header-mobile-container ul li div {
  color: var(--white);
  line-height: 29px;
  font-size: var(--font-size-m);
  margin-bottom: 10px;
}
.header-mobile-container ul li a.active {
  color: var(--buster);
}
.nav-item-mob:hover {
  background-color: transparent !important;
}
.nav-item.activeprofile .login-nav-item svg path {
  fill: var(--blue-1) !important;
  height: 30px;
}
.hp-landing-cirs::before {
  font-size: 117px;
  line-height: 24px;
  font-style: normal;
  color: var(--white);
}
.hp_landing_feature::before {
  font-size: 70px;
  line-height: 24px;
  font-style: normal;
  color: var(--purple);
}
.hp_landing_feature {
  margin-bottom: 25px;
}
.hp_lamp::before {
  content: "\E000\00FE0E";
}
.hp_ai::before {
  content: "\E005\00FE0E";
}
.hp_smile::before {
  content: "\E003\00FE0E";
  font-size: 148px;
}
.hp_camera::before {
  content: "\E002\00FE0E";
}
.hp_help::before {
  content: "\E001\00FE0E";
  color: #fdda1d;
}
.hp_ua::before {
  content: "\E009\00FE0E";
}

.review-answer-date .star svg {
  width: 25px;
}

.scrollable-text {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

a:hover svg {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

/* General Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Make it rounded */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Make it rounded */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color on hover */
}

/* Ensure compatibility with scrollable elements */
.scroll-container {
  overflow-y: auto;
  /* Allow scrolling on specific elements */
}

.terms-desc {
  width: 100%;
  margin: 0 auto;
}

.terms-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: max-height 0.3s ease, margin 0.3s ease;
  width: 100%;
}

.terms-header {
  background-color: #f5f5f5;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.terms-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #00695c;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #00695c;
}

.terms-content {
  padding: 16px;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}