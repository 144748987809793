@media (min-width: 991px) and (max-width: 1300px) {
  .banner-right-cir {
    width: 94%;
  }
  .pngwing-1 {
    width: 69%;
  }
  .reg-title-6 {
    font-size: 36px;
  }
  .reg-desc-6 {
    font-size: 21px;
    width: 100%;
  }
  .nav-item {
    font-size: 20px;
    padding: 11px 24px;
  }

  .reviews-container {
    border-radius: 346px/101px;
  }

  .feature-item {
    margin-bottom: 50px;
  }
  .features2-items-purple svg {
    width: 15%;
  }
  .features2-items-white {
    text-align: left;
    justify-content: left;
    align-items: center;
    font-size: 20px;
  }
  /* .features2 {
    padding-top: 208px;
  } */
  .help-button-con {
    left: 0;
    bottom: -11%;
  }
}
@media (max-width: 1400px) {
  .profile-pregnancy-right-con {
    margin-top: 50px;
  }
  .terms-cir-4 {
    top: 877px;
  }
  .terms-cir-3 {
    top: 752px;
  }
  .babycheck-cir-3 {
    top: 686px;
  }
}
@media (min-width: 1450px) {
  .landing-babycheck {
    display: block !important;
  }
}
@media (max-width: 1250px) {
  .birthday-container .form-input {
    padding: 30px 10px;
  }
  .babycheck-cir-1 {
    right: 40px;
    left: unset;
  }
  .babycheck-cir-3 {
    top: 685px;
  }
}

@media (max-width: 1200px) {
  .aboutus-bottom-body-right svg {
    width: 50px;
  }
  .aboutus-bottom-body-right div {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blog-info-right-container .star-rating svg {
    width: 30px;
  }
  .blog-cir-babycheck:not(.babycheck-central-circle-button) {
    right: 4px;
  }
  .blog-info-right-container {
    width: 552px;
  }
  .blog-info-left-container {
    width: 646px;
  }

  .pngwing-login {
    display: none;
  }
  .blog-cir-babycheck:not(.babycheck-central-circle-button),
  .logo-hp svg {
    width: 144px;
    height: 144px;
  }
  .blog-cir-1,
  .blog-cir-3 {
    width: 215px;
  }
  .nav-item {
    font-size: 17px;
    margin-right: 15px;
  }
}
@media (min-width: 991px) {
  .navbar {
    background-color: unset;
    box-shadow: none !important;
  }

  .nav-item-mob {
    display: none;
  }
  .features2-items-purple {
    padding-right: 5%;
  }
  .reg-5 svg {
    width: 150px;
  }
}
@media (max-width: 991px) {
  .terms-cir-4,
  .terms-cir-3 {
    display: none;
  }
  .terms-desc {
    padding: 80px 64px 136px;
  }
  .terms-container {
    border-radius: 252px;
  }
  .terms-cir-3 {
    top: 984px;
  }
  .faq-cir-3 {
    display: none;
  }
  .faq-list-items.open {
    width: 100%;
  }
  .faq-child-container.open {
    border-radius: 0 0 60px 60px;
  }
  .faq-child-container {
    padding: 20px;
  }
  .blog-pagination-square-white,
  .blog-pagination-square-blue {
    width: 50px;
    height: 50px;
    padding: 8px 11px;
  }
  .blog-pagination-square-blue svg {
    width: 30px;
    height: 30px;
  }
  .profile-reg-1 {
    font-size: 23px;
  }
  .article_mainimage {
    min-height: 500px;
  }
  .article-content-container {
    margin-top: 184px;
  }
  .blog-info-right-container .star-rating svg {
    width: 31px;
  }
  .blog-info-right-container .star-rating {
    margin-right: 20px;
  }
  .blog-tab-contents img {
    border-width: 13px !important;
  }
  .blog-info-left-container {
    width: 414px;
  }
  .blog-info-left-date,
  .blog-info-left-author {
    font-size: 18px;
  }
  .blog-info {
    bottom: -120px;
  }
  .blog-info-left-circle {
    height: 96px;
    width: 96px;
  }
  .blog-info-right-container {
    width: 414px;
    height: 155px;
    left: 234px;
  }
  .blog-recently-reg-1 {
    font-size: 26px;
  }
  .blog-cir-1,
  .blog-cir-2,
  .blog-cir-3,
  .blog-cir-babycheck:not(.babycheck-central-circle-button),
  .logo-hp {
    display: none !important;
  }
  #blog .header-svg {
    padding-bottom: 150px;
  }
  .aboutus-cir-1 {
    right: 0;
    left: unset;
  }
  .aboutus-cir-3,
  .aboutus-cir-4 {
    width: 150px;
  }

  .footer-button {
    padding: 24px 52px;
    font-size: 17px;
    margin: 0 4px;
  }
  .help-button {
    width: 90%;
  }
  .video-frame {
    height: auto;
    width: 97%;
  }
  .nav-item {
    font-size: 19px;
    padding: 11px 24px;
  }
  .reg-5 svg {
    transform: scale(0.6);
  }
  .help-button-con {
    left: 0;
  }

  .top-banner {
    height: auto;
    flex-direction: column-reverse;
  }
  .banner-right-container {
    height: 900px;
    /* background-color: var(--buster); */
  }
  .banner-right-cir {
    display: none;
  }
  .reg-5 {
    width: 22%;
    padding: unset;
    height: 194px;
    display: flex;
    justify-content: center;
    margin-bottom: 31px;
  }

  .reg-6 {
    width: 49%;
    padding-top: unset;
    height: 421px;
  }
  .reg-container-6 {
    padding-top: 12%;
  }

  .reg-desc-6 {
    width: unset;
    font-size: 16px;
  }
  .banner-left {
    margin-top: -782px;
    position: relative;
    padding-bottom: 180px !important;
    z-index: 1;
  }
  .cir1-container {
    margin-top: -16%;
    margin-bottom: 7vh;
  }
  .cir4-container {
    margin-top: -9%;
  }
  .cir-1 {
    width: 152px;
    height: 170px;
  }
  .features2-items-white {
    font-size: 16px;
    text-align: center;
  }
  /* .fixed-top .navbar {
    background-color: var(--blue-1);
  } */
  .fixed-top {
    position: fixed !important;
  }
  .nav-item.activeprofile ul {
    background-color: var(--buster) !important;
    padding-top: 64px !important;
  }
  .nav-item.activeprofile ul li,
  .nav-item.activeprofile ul li a {
    color: var(--blue-1) !important;
  }
  .pngwing-1 {
    width: 42%;
    height: 85%;
    top: 9%;
  }
  .features2-items-purple {
    padding-right: 4%;
  }
  .graph1-desc {
    font-size: 3vw;
    margin: 0 auto;
    width: 61%;
  }
  .graph1-container {
    border-radius: 140px;
  }
  .reviews-title {
    font-size: 28px;
  }
  .reviews-container {
    padding-bottom: 200px;
    border-radius: 167px/191px;
  }
  .hp-landing-cirs::before {
    font-size: 100px;
  }
  .banner-left-login {
    margin-top: 150px !important;
  }
  .top-banner-login {
    padding-bottom: 72px !important;
  }
  .top-banner-login.register {
    padding-bottom: 201px !important;
  }
  .header-svg svg {
    width: 130px;
  }
  .profile-general-right-img {
    width: 150px;
    margin-bottom: 20px;
    height: 150px;
    display: flex;
    justify-content: end;
    float: right;
    margin: 0 !important;
  }
  .birthday-container > div {
    width: 20%;
  }
  .profile-general-middle .form-input {
    width: 100%;
  }
  .profile-general-middle .form-group,
  .profile-general-right .form-group {
    width: 67%;
    margin: 0 !important;
  }
  .profile-middle-button {
    width: 66%;
  }
  .profile-container form {
    margin-bottom: 66px;
  }
  .profile-right-button {
    width: 120px;
    font-size: 16px;
    right: 50px;
  }
}
@media (max-width: 850px) {
  .article-toggler-slide {
    font-size: 22px;
    padding-right: 15px;
  }
  .blog-search-icon svg {
    width: 30px;
  }
  .blog-recently-grid,
  .blog-tab-grid {
    padding: 0 33px;
  }
  .blog-serach-container {
    width: 348px;
    right: 24px;
  }
  .pngwing-1 {
    width: 39%;
  }
  .reg-title-6 {
    font-size: 4.5vw;
  }
}
@media (max-width: 768px) {
  .review-answer-date .star svg {
    width: 17px;
  }
  .babycheck-cir-3 {
    display: none;
  }
  .terms-title.terms {
    font-size: 30px;
  }

  .aboutus-middle-back-box {
    font-size: 18px;
    font-weight: bold;
  }
  .babycheck-cir-3 {
    bottom: 0;
    top: unset;
  }
  .answer-rating {
    display: none !important;
  }
  .answer-list .answer-parent .answer-content-parent,
  .answer-list .answer-parent .answer-content {
    width: 100%;
  }
  .review-answer-child .answer-content {
    width: 95% !important;
  }
  .blog-recently-grid,
  .blog-tab-grid {
    justify-content: center !important;
  }
  .article-reviews form {
    width: 100% !important;
  }
  .blog-recently-reg-1 {
    font-size: 23px;
  }
  .blog-tab-more button {
    font-size: 20px;
  }
  .blog-recently-grid,
  .blog-tab-grid {
    padding: 0;
  }
  .blog-tab-item {
    width: 278px;
  }
  .blog-serach-container {
    position: relative !important;
    width: 83%;
    margin: 20px auto 70px auto;
  }
  .aboutus-bottom-head {
    width: 100% !important;
    border-radius: 50px 50px 0 0 !important;
  }
  .aboutus-bottom-body {
    border-radius: 0 0 50px 50px !important;
    flex-direction: column;
  }
  .aboutus-bottom-body-right div {
    margin-bottom: 40px !important;
    display: flex;
    justify-content: center !important;
    flex-direction: row;
  }
  .aboutus-bottom-body-right svg {
    width: 65px;
  }
  .footer-button {
    padding: 24px 38px;
    font-size: 14px;
    margin: 0 4px;
  }
  .cir-1 {
    width: 120px;
    height: 140px;
  }
  .hp-landing-cirs::before {
    font-size: 80px;
  }
  .fa-play svg {
    width: 74px;
  }
  .feature-item {
    margin-bottom: 30px;
  }
  .reg-desc-6 {
    padding-right: 23px;
  }
  .reg-6 {
    width: 55%;
    height: 437px;
  }
  .features2-items-purple {
    padding-right: 7%;
  }
  .features2-items-white {
    font-size: 18px;
  }
  .cir1-container {
    margin-bottom: 7vh;
  }
  .cir4-container {
    margin-top: -7%;
  }
  .reviews-title {
    font-size: 24px;
  }
  .babycheck-central-circle {
    width: 517px;
    height: 517px;
    border-width: 15px;
  }
}
@media (max-width: 650px) {
  #article-content p {
    font-size: 17px;
  }
  .blog-info-left-circle {
    width: 60px;
    height: 60px;
  }
  .blog-info-right-container {
    width: 100%;
    height: unset;
    /* top: 234px; */
    position: unset;
    /* left: 0; */
    border-radius: 0 0 30px 30px;
    flex-direction: row-reverse;
    z-index: 2;
  }
  .article-content-container {
    margin-top: 44px;
  }
  .blog-info-right-container .star-rating svg {
    width: 20px;
  }
  .blog-info-right-container .blog-view {
    margin-bottom: 17px;
  }
  .blog-info-left-date {
    color: var(--buster) !important;
  }
  .blog-info-left-container {
    width: 100%;
    background-color: var(--purple);
    border-radius: 0;
    height: 80px;
    display: flex;
    padding: 0;
    border-radius: 30px 30px 0 0;
    justify-content: center;
    padding-top: 12px;
  }
  .blog-info-left-date,
  .blog-info-left-author {
    font-size: 20px;
    color: var(--white);
  }
  .blog-info {
    width: 100%;
    position: unset;
    flex-direction: column;
  }
  .blog-tab-contents img {
    border-width: 9px !important;
    border-radius: 30px;
  }
  .blog-tab-item {
    width: 350px;
  }
  .header-svg svg {
    width: 130px;
  }
  .top-banner-aboutus:not(.top-banner-babycheck) {
    padding-top: 113px !important;
  }

  .help-container {
    border-radius: 85px;
  }
  .help-col {
    padding-left: 6% !important;
  }
  .cir4-container {
    margin-top: -10%;
  }
  .banner-right-container {
    display: none;
  }

  .banner-left {
    margin-top: 32px;
    padding-top: 100px !important;
    padding-bottom: 103px !important;
    /* background-color: var(--buster); */
  }
  .reg-6 {
    width: 84%;
  }
  .reg-title-6 {
    font-size: 7vw;
    width: 373px;
  }
  .reg-5 {
    width: 34%;
    height: 205px;
  }
  .babycheck-central-circle {
    width: 368px;
    height: 376px;
    border-width: 15px;
  }
  .babycheck-central-circle-childs {
    width: 110px;
    height: 110px;
  }
  .babycheck-central-circle-childs svg {
    width: 75px;
  }
  .babycheck-central-circle-childs.show div:first-child {
    font-size: 13px;
  }
  .babycheck-central-circle-childs.show {
    padding: 16px;
  }
  .babycheck-central-circle-childs.show div:last-child {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .faq-child-items {
    padding: 10px 50px;
    border-radius: 60px;
  }
  #blog .header-svg {
    padding-bottom: 97px;
  }
  .article-toggler-slide {
    /* height: 50px; */
    font-size: 16px;
    background-color: unset;
    box-shadow: unset;
    color: var(--purple);
    font-size: 22px;
    max-width: 100%;
    margin-bottom: 15px;
  }
  /* .blog-tab-contents {
    border-radius: 30px;
  } */
  .blog-tab-more button {
    font-size: 18px;
  }
  .aboutus-bottom-body-left-text {
    font-size: 17px;
  }
  .aboutus-bottom-body-left-container svg {
    width: 30px;
  }
  .aboutus-bottom-body-right svg {
    width: 70px;
  }
  .aboutus-bottom-body-right {
    padding: 20px;
  }
  .footer-con {
    flex-direction: column;
    width: 100%;
  }
  .footer-items {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .footer-items a {
    display: flex;
    margin-bottom: 20px;
    width: 70%;
  }
  .footer-con a {
    display: flex;
    margin-bottom: 20px;
  }
  .footer-button {
    width: 100%;
    font-size: 17px;
  }
  .help-button {
    height: 80px;
    font-size: 25px;
  }
  .help-col {
    margin-top: 30px;
  }
  .features2-items-purple {
    padding-right: 5%;
  }
  .features2-items-white {
    font-size: 16px;
  }
  .features2-items-purple svg {
    height: 54px;
    width: 51px;
  }
  .feature-item svg {
    margin-bottom: 12px;
    width: 16%;
  }
  .graph1-desc {
    font-size: 15px;
    line-height: 20px;
  }
  .feature-item h3 {
    font-size: 15px;
    line-height: 24px;
  }
  .pngwing-1 {
    width: 52%;
  }
  .reviews-title {
    font-size: 22px;
  }
  .reviews-container {
    border-radius: 50px;
  }
  .reg-title-6 {
    font-size: 7vw;
    width: 353px;
  }
  .banner-left-login {
    margin-top: 40px !important;
  }
  .top-banner-login {
    padding-bottom: 258px !important;
  }
  .top-banner-login.register {
    padding-bottom: 310px !important;
  }
  #login .form-input {
    padding-right: 3px;
  }
  .login-form-container {
    padding: 98px 82px;
  }
  .birthday-container .form-input {
    padding: 10px;
  }
  .profile-general-right-img {
    float: unset;
    margin-bottom: 30px !important;
  }
  .birthday-container > div {
    width: 25%;
  }
  .profile-general-middle .form-group,
  .profile-general-right .form-group {
    width: 100%;
    margin: 0 !important;
  }
  .profile-middle-button {
    width: 100%;
    font-size: 16px;
    height: 60px;
  }
  .profile-left-button {
    font-size: 16px;
    height: 60px;
  }
}
@media (max-width: 450px) {
  .top-banner-aboutus {
    padding-top: 150px !important;
    padding-bottom: 300px !important;
  }
  .terms-desc {
    padding: 48px 24px;
    font-size: 16px;
  }
  .terms-title {
    font-size: 25px;
  }
  .terms-title.terms {
    font-size: 20px;
  }

  .terms-button {
    width: 100px;
    height: 50px;
    font-size: 16px;
  }
  .profile-right-button {
    right: 35px;
  }
  .profile-pregnancy-left-con,
  .profile-pregnancy-right-con {
    padding: 0 10px;
  }
  .profile-pregnancy-grid {
    grid-template-columns: repeat(auto-fit, minmax(126px, 2fr));
  }

  .blog-tab-contents {
    border-radius: 0;
  }
  .checkmail-container,
  .checkmail-desc {
    width: unset;
  }
  .register-name {
    flex-direction: column !important;
  }
  .register-name div:first-child {
    margin-bottom: 20px;
  }
  .review-answer-text,
  .review-answer-date,
  .review-answer-name {
    font-size: 14px !important;
  }
  .review-answer-text {
    padding: 10px 0 !important;
  }
  .article-reviews > div {
    padding: 10px 10px;
  }
  .blog-tab-contents {
    z-index: 0;
  }
  .blog-recently-reg-1 {
    font-size: 20px;
    width: 90%;
  }
  .blog-tab-more button {
    font-size: 16px;
  }
  .blog-toggler-slide {
    font-size: 18px;
  }
  .aboutus-top-box {
    font-size: 14px;
    padding: 80px 20px;
  }
  .footer-button {
    padding: 24px 3px;
  }
  .footer-copywrite span {
    font-size: 15px;
  }
  .footer-copywrite svg {
    margin-right: 16px;
    width: 100px;
  }
  .help-container {
    border-radius: 65px;
  }

  .help-cir-desc {
    width: 84%;
    border-radius: 80px 0px 0px 80px;
  }
  .help-button {
    height: 80px;
    font-size: 20px;
  }
  .features2-items-white {
    font-size: 14px;
  }
  .logo-title {
    font-size: var(--font-size-m);
  }
  .nav-item-mob svg {
    width: 25px;
  }
  .burger {
    font-size: 9px !important;
  }
  .nav-item-mob.activeprofile .login-nav-item {
    padding: 5px 10px;
  }
  .dropdown_menu ul li {
    font-size: 15px;
    font-weight: bold;
  }
  .pngwing-1 {
    position: absolute;
    right: 4%;
    width: 71.4%;
    height: 93%;
    top: 7%;
  }
  .reg-desc-6 {
    line-height: 27px;
  }
  .reg-6 {
    width: 96%;
  }
  .reg-title-6 {
    font-size: 9vw;
    width: 300px;
  }
  .reg-5 {
    width: 34%;
    height: 205px;
  }
  .login-form-container {
    padding: 98px 36px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .login-form-container form {
    width: 100%;
  }
  #login .form-input {
    padding-left: 20px;
  }
  .profile-left-c {
    flex-direction: column !important;
  }
  .profile-left-button {
    margin-top: 60px;
  }
  .babycheck-central-circle {
    border: none;
    width: 100%;
    display: grid;
    /* grid-auto-rows: unset; */
    height: unset;
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense;
    direction: rtl;
    grid-gap: 10px;
    grid-row-start: 5;
    justify-items: center;
  }
  .babycheck-container {
    margin: 0 !important;
    width: 100%;
  }
  .babycheck-central-circle-childs {
    width: 130px;
    height: 130px;
  }
  .babycheck-central-circle-button {
    position: unset;
    width: 150px;
    height: 150px;
    font-weight: 400;
    font-size: 21px;
  }
  .babycheck-central-circle-childs svg {
    width: 85px;
  }
  .babycheck-central-circle-childs.show div:first-child {
    font-size: 15px;
  }
  .babycheck-central-circle-childs {
    position: unset;
    display: flex !important;
    /* float: left;   */
  }
  .babycheck-central-circle-childs.show div:last-child {
    font-size: 22px;
  }
}
@media (max-width: 400px) {
  .blog-tab-item {
    width: 296px;
  }
  .blog-serach-container {
    width: 95%;
    margin: 20px auto 70px 32px;
  }
}
